import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from 'react-scroll-trigger';
import job from '../assets/img/11.webp';
import ins from '../assets/img/12.webp';
import awd from '../assets/img/13.webp';
import grad from '../assets/img/14.webp';
import clock from '../assets/img/15.webp';
import "./AboutBsp.css";
import { useSelector, shallowEqual } from "react-redux";



function HomeContent() {

  const [counterState, setCounterState] = useState(false);
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  return (

    <>
    {!isMobileState ? 
            <div className="counter-wrapper pr9" >
            <div className="counter-wrapper1">
            <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}> <div className="counter">
                <h1 className="count">
                <div> <img src={grad} alt="count" width="90" height="90" /></div>
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={1000}
                    duration={5}
                  />}+</div></h1>
                <p>Students</p>
              </div>
              </ScrollTrigger>
            <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
                <div> <img src={ins}  alt="insurance" width="90" height="90" /></div>
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={14}
                    duration={5}
                  />}+</div></h1>
                <p>Years of Exp</p>
              </div>
              </ScrollTrigger>
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}><div className="counter">
                <h1 className="count">
                <div> <img src={clock} alt="clock" width="90" height="90" /></div>
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={200}
                    duration={5}
                  />}K+</div></h1>
                <p>Mentoring Hours</p>
              </div></ScrollTrigger>
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
                <div> <img src={awd} alt="award" width="90" height="90" /></div>
                <div>   {counterState &&
                  <CountUp
                    start={0}

                    end={30}
                    duration={5}
                  />}+</div></h1>
                <p>Awards Won</p>
              </div>
              </ScrollTrigger>
              
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
               <div> <img src= {job} alt="count" width="90" height="90" /></div>
               <div> {counterState &&
                  <CountUp
                    start={0}

                    end={100}
                    duration={5}
                  />}%</div></h1>
                <p>Placement Support</p>
              </div>
              </ScrollTrigger>
              
            </div>
            </div>:
            <div className="mblcounter">
               <div className="cntr">
        <div className="left">

              <div> <img src={grad} alt="count"/></div>
              </div>
              <div className="right">
                
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}> <div className="counter">
                <h1 className="count">
             
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={1000}
                    duration={5}
                  />}+</div></h1>
                <p>Students</p>
              </div>
              </ScrollTrigger>      
        </div>
       </div>
              <div className="cntr">
              <div className="left">

              <div> <img src={ins}  alt="insurance"/></div>
              </div>
              <div className="right">
                
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
               
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={14}
                    duration={5}
                  />}+</div></h1>
                <p>Years of Exp</p>
              </div>
              </ScrollTrigger>   
        </div>
        </div>
       
       <div className="cntr">
        <div className="left">

        <div> <img src={clock} alt="clock"/></div>
              </div>
              <div className="right">
                
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}><div className="counter">
                <h1 className="count">
               
                <div>  {counterState &&
                  <CountUp
                    start={0}

                    end={200}
                    duration={5}
                  />}K+</div></h1>
                <p>Mentoring <br/> Hours</p>
              </div></ScrollTrigger>   
        </div>
       </div>
       <div className="cntr">
        <div className="left">

        <div> <img src={awd} alt="award" /></div>
        </div>
              <div className="right">
                
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
                <div>   {counterState &&
                  <CountUp
                    start={0}

                    end={30}
                    duration={5}
                  />}+</div></h1>
                <p>Awards Won</p>
              </div>
              </ScrollTrigger>     
        </div>
       </div>
       <div className="cntr">
        <div className="left">

        <div> <img src= {job} alt="count"/></div>
        </div>
              <div className="right">
                
              <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
              <div className="counter">
                <h1 className="count">
              
               <div> {counterState &&
                  <CountUp
                    start={0}

                    end={100}
                    duration={5}
                  />}%</div></h1>
                <p>Placement <br/> Support</p>
              </div>
              </ScrollTrigger>
        </div>
       </div>
            </div>
}

    </>
  );
}

export default HomeContent;